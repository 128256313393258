module.exports = {
  staticHost: 'https://uc-dev.weiwenjia.com',
  buildPath: '/web/',
  publicPath: '/web/',
  oosHost: 'https://uc-dev.weiwenjia.com',
  AppConf: {
    redirectHost: 'https://uc-dev.weiwenjia.com',
    publicPath: '/web/index.html',
    wpsAppId: 'SX20220218IEHISC',
    dingdingClientId: 'suitemqduomhoxtyyxewj',
    api: {
      faye: 'https://faye-dev.ikcrm.com/faye',
      crmFaye: 'https://faye-dev.ikcrm.com/faye',
      domain: 'https://uc-dev.weiwenjia.com',
      versionDomain: 'https://crm-h5-test-cdn.ikcrm.com',
      pathPrefix: '/api',
    },
    lixiaoyunHost: "https://www-test.lixiaoyun.com",
    CRMHandoverHost: {
      platform: 'https://lixiao-dev.ikcrm.com',
      ik: 'https://ik-dev.ikcrm.com',
      lx: 'https://lxcrm-dev.weiwenjia.com',
      '43ae7cad678c4664bfd9492ae437cb82': 'https://lxcrm-dev.weiwenjia.com',
      dingding: 'https://ding-dev.ikcrm.com',
      dinglogin: 'https://www-dev.ikcrm.com/users/sign_in'
    },
    jumpToEnterpriseWeChatAppToken: [
      ['9de95972d5c3455ba16a6cf8f4872dd4', 'https://lixiao-dev.ikcrm.com', 'CRM'],
      ['ace93093b51aa0e8f7a38a0fa37eaad7', 'https://lixiaojxc-dev.ikcrm.com/dashboard', 'JXC'],
      ['e7fea9637b984b57a654b890c96e40de', 'https://lxcrm-dev.weiwenjia.com', 'CRM'],
    ],
    jumpToWhere: {
      CRM: 'http://www-test.lxsales.com/uc/wx/dev',
      JXC: 'http://www-test.lxsales.com/uc/wx/dev',
    },
    jxcHost: {
      ik: 'https://ikjxc-dev.ikcrm.com/users/auth'
    },
    appToken: {
      IK: 'f6620ff6729345c8b6101174e695d0ab',
      QWCRM: '9de95972d5c3455ba16a6cf8f4872dd4',
      XLJQR: 'ee2b515eedc6d907bbfcf96c96b3734d',
      QWJXC: 'ace93093b51aa0e8f7a38a0fa37eaad7',
      DLJXC: 'a329426fa9d79d058d1d3812a7e8bdf2',
      CRM1688: '7ebb92aeeccf4554b475808668b8b53e',
      DINGDING: '6f85c8d2540a48d08c634801f8648e9e',
      FEISHU: "e7fea9637b984b57a654b890c96e40de",
      QIDIAN: "7fc5ba42517b4b689f791ed90fe28f90"
    },
    clientId: 'cli_a3c820e48c7ed00e',
    productToken: [  // 老的三个产品，CRM励销独立版，励销搜客宝，励销机器人
      '43ae7cad678c4664bfd9492ae437cb82',
      '766fcf9a46064513ac5bb7ae4af620f1',
      '5e1cce3d8a3b4a76a414b10d2bfb667af'
    ],
    templates: {
      importUser: 'https://uc-files-dev.weiwenjia.com/file-1713342724500用户导入模版.xlsx'
    },
    dlOAuth:{
      domain:'https://open.weixin.qq.com/connect/oauth2/authorize',
      lxUrl: 'https://lxcrm-dev.weiwenjia.com/wx/home',
      qwUrl: 'https://lixiao-dev.ikcrm.com/wx/home'
    }
  },
};
